export const images = [
  'Decollazione-cm.100x150-2012-olio-su-tela.jpg',
  'Heartless-Burning-cm120x100-2011.jpg',
  'Heartless-Efflorescence-100x120cm-2011.jpg',
  'Heartless-Sorrow-cm120x100-2011.jpg',
  'My-FuckinBlack-Heart-50x40-2011.jpg',
  'Natura-Morta-Autoritratto-di-CD-2016.jpg',
  'Natura-Morta-Batman-100x150-Olio-su-Tela-2017w.jpg',
  'Natura-Morta-Bucce-50x40-2014.jpg',
  'Natura-Morta-Farmacia-Contemporanea40x50-olio-su-tela.jpg',
  'Natura-Morta-Mein-Kampf+Antidepressivo-Sedativo-Miorilassante-Olio-su-tela-40x50-2012.jpg',
  'Natura-Morta-Ossa-40x50cm-Olio-su-tela-2016w.jpg',
  'Natura-Morta-Sesso-Droga-e-Rockn-Roll-50x40-2013-.jpg',
  'Natura-morta-Il-Trionfo-della-Natura-sulla-Morte-40x50-olio-su-tela2.jpg',
  'Natura-morta-Nine-Inch-Nails-2016-olio-su-tela-100x150.jpg',
  'Nel-Buio-35-150x100-2011.jpg',
  'Nel-buio-37-150x100-olio-su-tela1.jpg',
  'Nuda-veritas-cm200x100-2010-L-istinto1-970x1949.jpg',
  'Nuda-veritas-cm200x100-2010-La-ragione-970x1949.jpg',
  'Senza-Titolo-N4.jpg',
  'Simone-Fazio-Senza-Titolo-10-olio-su-tela-100x150-2016.jpg',
  'Simone-Fazio-Senza-Titolo-11-olio-su-carta-35x50-2017.jpg',
  'Simone-Fazio-Senza-Titolo-16-olio-su-carta-35x50-2017.jpg',
  'Simone-Fazio-Senza-Titolo-4-olio-su-carta-35x50-2017.jpg',
  'Simone-Fazio-Senza-Titolo-8-olio-su-tela-70x100-2016-970x679.jpg',
]
