import * as THREE from 'three'
import EffectComposer from './lib/postprocessing/EffectComposer.js'
import RenderPass from './lib/postprocessing/RenderPass.js'
import ShaderPass from './lib/postprocessing/ShaderPass.js'
// import MaskPass from './lib/postprocessing/MaskPass.js'
import CopyShader from './lib/shaders/CopyShader.js'
import FilmShader from './lib/shaders/FilmShader.js'
import RGBShiftShader from './lib/shaders/RGBShiftShader.js'

import BadTVShader from './BadTVShader'
import StaticShader from './StaticShader'
import Noise from './noise'

export default function() {
  var images = []

  // EffectComposer(THREE)
  // // RenderPass(THREE)
  // // ShaderPass(THREE)
  // MaskPass(THREE)
  // CopyShader(THREE)
  // CopyShader(THREE)
  // FilmShader(THREE)
  // RGBShiftShader(THREE)
  // BadTVShader(THREE)
  // StaticShader(THREE)

  const noiseSound = Noise()

  //Bad TV Shader Demo
  //Using Three.js r.75
  //by Felix Turner / www.airtight.cc / @felixturner

  var camera, scene, renderer
  var videoMaterial
  var composer
  var shaderTime = 0
  var badTVParams, badTVPass
  var staticParams, staticPass
  var rgbParams, rgbPass
  var filmParams, filmPass
  var renderPass, copyPass
  var plane

  function getImage(images) {
    if (!images) return
    return images[Math.floor(Math.random() * images.length)]
  }

  function loadImage(imageUrl) {
    const loader = new THREE.TextureLoader()
    loader.setCrossOrigin('')
    return loader.load(imageUrl)
  }

  function init(
    DOMcontainer,
    {
      userBadTVParams = {},
      userStaticParams = {},
      userRgbParams = {},
      userFilmParams = {},
    } = {}
  ) {
    // camera = new THREE.PerspectiveCamera(55, 1080 / 720, 20, 3000)
    camera = new THREE.PerspectiveCamera(70, 1, 1, 1000)
    camera.position.z = 1000
    scene = new THREE.Scene()

    var videoTexture = loadImage(getImage(images))
    videoTexture.minFilter = THREE.LinearFilter
    videoTexture.magFilter = THREE.LinearFilter

    videoMaterial = new THREE.MeshBasicMaterial({
      map: videoTexture,
    })

    //Add video plane
    var planeGeometry = new THREE.PlaneGeometry(1080, 720, 1, 1)
    plane = new THREE.Mesh(planeGeometry, videoMaterial)
    scene.add(plane)
    plane.z = 0
    plane.scale.x = plane.scale.y = 1.45

    //init renderer
    renderer = new THREE.WebGLRenderer()
    renderer.setSize(800, 600)

    DOMcontainer.appendChild(renderer.domElement)

    //POST PROCESSING
    //Create Shader Passes
    renderPass = new RenderPass(scene, camera)
    badTVPass = new ShaderPass(BadTVShader)
    rgbPass = new ShaderPass(RGBShiftShader)
    filmPass = new ShaderPass(FilmShader)
    staticPass = new ShaderPass(StaticShader)
    copyPass = new ShaderPass(CopyShader)

    //set shader uniforms
    filmPass.uniforms.grayscale.value = 0

    //Init DAT GUI control panel
    badTVParams = {
      mute: true,
      show: true,
      distortion: 3.0,
      distortion2: 1.0,
      speed: 0.3,
      rollSpeed: 0.1,
      ...userBadTVParams,
    }

    staticParams = {
      show: true,
      amount: 0.5,
      size: 4.0,
      ...userStaticParams,
    }

    rgbParams = {
      show: true,
      amount: 0.005,
      angle: 0.0,
      ...userRgbParams,
    }

    filmParams = {
      show: true,
      count: 800,
      sIntensity: 0.9,
      nIntensity: 0.4,
      ...userFilmParams,
    }

    onToggleShaders()

    onParamsChange()

    // window.addEventListener('resize', onResize, false)
    // renderer.domElement.addEventListener('click', randomizeParams, false)

    onResize(true)

    // ;(userBadTVParams || userStaticParams || userRgbParams || userFilmParams) &&
    //   randomizeParams()

    noiseSound.start()
  }

  function onParamsChange() {
    //copy gui params into shader uniforms
    badTVPass.uniforms['distortion'].value = badTVParams.distortion
    badTVPass.uniforms['distortion2'].value = badTVParams.distortion2
    badTVPass.uniforms['speed'].value = badTVParams.speed
    badTVPass.uniforms['rollSpeed'].value = badTVParams.rollSpeed

    staticPass.uniforms['amount'].value = staticParams.amount
    staticPass.uniforms['size'].value = staticParams.size

    rgbPass.uniforms['angle'].value = rgbParams.angle * Math.PI
    rgbPass.uniforms['amount'].value = rgbParams.amount

    filmPass.uniforms['sCount'].value = filmParams.count
    filmPass.uniforms['sIntensity'].value = filmParams.sIntensity
    filmPass.uniforms['nIntensity'].value = filmParams.nIntensity
  }

  function randomizeParams() {
    // if (Math.random() < 0.2) {
    //   //you fixed it!
    //   badTVParams.distortion = 0.1
    //   badTVParams.distortion2 = 0.1
    //   badTVParams.speed = 0
    //   badTVParams.rollSpeed = 0
    //   rgbParams.angle = 0
    //   rgbParams.amount = 0
    //   staticParams.amount = 0
    // } else {
    badTVParams.distortion = Math.random() * 10 + 0.1
    badTVParams.distortion2 = Math.random() * 10 + 0.1
    badTVParams.speed = Math.random() * 0.4
    badTVParams.rollSpeed = Math.random() * 0.2
    rgbParams.angle = Math.random() * 2
    rgbParams.amount = Math.random() * 0.03
    staticParams.amount = Math.random() * 0.2
    // }

    plane.material.map = new THREE.TextureLoader().load(getImage(images))
    plane.material.needsUpdate = true
    onParamsChange()

    // noiseSound.random();
  }

  function onToggleShaders() {
    //Add Shader Passes to Composer
    //order is important
    composer = new EffectComposer(renderer)
    composer.addPass(renderPass)

    if (filmParams.show) {
      composer.addPass(filmPass)
    }

    if (badTVParams.show) {
      composer.addPass(badTVPass)
    }

    if (rgbParams.show) {
      composer.addPass(rgbPass)
    }

    if (staticParams.show) {
      composer.addPass(staticPass)
    }

    composer.addPass(copyPass)
    copyPass.renderToScreen = true
  }

  function animate() {
    shaderTime += 0.1
    badTVPass.uniforms['time'].value = shaderTime
    filmPass.uniforms['time'].value = shaderTime
    staticPass.uniforms['time'].value = shaderTime

    // if (video.readyState === video.HAVE_ENOUGH_DATA) {
    //     if (videoTexture) videoTexture.needsUpdate = true;
    // }

    requestAnimationFrame(animate)
    composer.render(0.1)
    // stats.update();
  }

  function onResize(force) {
    // renderer.setSize(window.innerWidth, window.innerHeight)
    // camera.aspect = window.innerWidth / window.innerHeight
    // camera.updateProjectionMatrix()

    resizeCanvasToDisplaySize(force)
  }

  function resizeCanvasToDisplaySize(force) {
    const canvas = renderer.domElement
    // look up the size the canvas is being displayed
    const width = canvas.clientWidth
    const height = canvas.clientHeight
    // adjust displayBuffer size to match
    if (force || canvas.width !== width || canvas.height !== height) {
      // you must pass false here or three.js sadly fights the browser
      renderer.setSize(width, height)
      camera.aspect = width / height
      camera.updateProjectionMatrix()

      // update any render target sizes here
    }
  }

  return {
    start(DOMcontainer, options) {
      init(DOMcontainer, options)
      animate()
    },
    stopSound() {
      noiseSound.stop()
    },
    onClick() {
      randomizeParams()
    },
    onWindowResize() {
      onResize()
    },
    setImages(newImages) {
      images = newImages
    },
  }
}
