function getWhiteNoise(audioContext) {
  var bufferSize = 2 * audioContext.sampleRate,
    noiseBuffer = audioContext.createBuffer(
      1,
      bufferSize,
      audioContext.sampleRate
    ),
    output = noiseBuffer.getChannelData(0)
  for (var i = 0; i < bufferSize; i++) {
    output[i] = Math.random() * 2 - 1
  }

  var whiteNoise = audioContext.createBufferSource()
  whiteNoise.buffer = noiseBuffer
  whiteNoise.loop = true
  whiteNoise.start(0)
  whiteNoise.connect(audioContext.destination)
  return whiteNoise
}

function getBrownNoise(audioContext) {
  var bufferSize = 4096
  var brownNoise = (function() {
    var lastOut = 0.0
    var node = audioContext.createScriptProcessor(bufferSize, 1, 1)
    node.onaudioprocess = function(e) {
      var output = e.outputBuffer.getChannelData(0)
      for (var i = 0; i < bufferSize; i++) {
        var white = Math.random() * 2 - 1
        output[i] = (lastOut + 0.02 * white) / 1.02
        lastOut = output[i]
        output[i] *= 3.5 // (roughly) compensate for gain
      }
    }
    return node
  })()
  brownNoise.connect(audioContext.destination)
  return brownNoise
}

function getPinkNoise(audioContext, destination) {
  var bufferSize = 4096
  var pinkNoise = (function() {
    var b0, b1, b2, b3, b4, b5, b6
    b0 = b1 = b2 = b3 = b4 = b5 = b6 = 0.0
    var node = audioContext.createScriptProcessor(bufferSize, 1, 1)
    node.onaudioprocess = function(e) {
      var output = e.outputBuffer.getChannelData(0)
      for (var i = 0; i < bufferSize; i++) {
        var white = Math.random() * 2 - 1
        b0 = 0.99886 * b0 + white * 0.0555179
        b1 = 0.99332 * b1 + white * 0.0750759
        b2 = 0.969 * b2 + white * 0.153852
        b3 = 0.8665 * b3 + white * 0.3104856
        b4 = 0.55 * b4 + white * 0.5329522
        b5 = -0.7616 * b5 - white * 0.016898
        output[i] = b0 + b1 + b2 + b3 + b4 + b5 + b6 + white * 0.5362
        output[i] *= 0.11 // (roughly) compensate for gain
        b6 = white * 0.115926
      }
    }
    return node
  })()

  pinkNoise.connect(destination)

  return pinkNoise
}

export default function() {
  const isSsr = typeof window === 'undefined'
  let audioContext = null

  if (!isSsr && (window.AudioContext || window.webkitAudioContext)) {
    audioContext = new (window.AudioContext || window.webkitAudioContext)()

    var gainNode = audioContext.createGain()
  }

  const noises = [getPinkNoise, getBrownNoise, getWhiteNoise]

  return {
    start(which) {
      if (!isSsr) {
        gainNode.gain.setValueAtTime(0.03, audioContext.currentTime)
        gainNode.connect(audioContext.destination)
        getPinkNoise(audioContext, gainNode)
      }
    },
    stop() {
      if (!isSsr) {
        gainNode.disconnect(audioContext.destination)
      }
    },
    random() {
      const noise = noises[Math.floor(Math.random() * noises.length)]

      noise(audioContext)
    },
  }
}
